import App from "./App.tsx";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { CssBaseline } from "@mui/material";
import { setContext } from "@apollo/client/link/context";
import "./main.css";

// HTTP Link to connect to your GraphQL endpoint
const httpLink = createHttpLink({
  uri: `${import.meta.env.DEV ? '' : import.meta.env.VITE_GRAPHQL_BASE}${import.meta.env.VITE_GRAPHQL_PATH}`
});

// Context function to dynamically set the authorization header
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Apollo Client setup
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  name: 'web',
  version: import.meta.env.VITE_APP_VERSION
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <CssBaseline />
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
