import Logo from "assets/logo-klover.svg";
import React, { useEffect, useState } from "react";
import linkFirebaseMutation from "operations/mutations/linkFirebase.mutation";
import { CUSTOM_EVENT_NAME } from "../constants";
import { ConfirmationResult, User, linkWithPhoneNumber } from "firebase/auth";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { LinkFirebaseInput } from "types/graphql/generated";
import { sendOnboardingEvent } from "../tracking";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "../App.styles";
import * as mParticle from '../tracking/mparticle';
import * as yup from "yup";

interface Step2Props {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  user: User | null;
  phone: string;
  setFormPhoneError: React.Dispatch<React.SetStateAction<string>>;
  confirmationResult?: ConfirmationResult;
  setConfirmationResult: React.Dispatch<
    React.SetStateAction<ConfirmationResult | undefined>
  >;
}

const Step2 = ({
  activeStep,
  setActiveStep,
  user,
  phone,
  setFormPhoneError,
  confirmationResult,
  setConfirmationResult,
}: Step2Props) => {
  interface FormInputs {
    otp: string;
  }

  const [otpError, setOtpError] = useState('');
  const [loading, setLoading] = useState(false);

  const validationSchema = yup
    .object({
      otp: yup.string().min(6).max(6).required(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [linkFirebase] = useMutation(linkFirebaseMutation);

  const handleSendAgain = () => {
    if (!user) return;
    linkWithPhoneNumber(user, phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      // update loading and error states
      setOtpError('');
      setLoading(true);

      if (!confirmationResult || !user) {
        throw new Error('Something went wrong');
      }

      // submit otp
      const result = await confirmationResult.confirm(data.otp);

      // get jwt token for graphql
      const token = await result.user.getIdToken()
        .catch((err) => {
          console.log('token err', err.message);
          return '';
        });

      if (!token) {
        localStorage.removeItem('token');
        throw new Error('No token');
      }

      localStorage.setItem('token', token);

      // trigger graphql call
      const { data:linkFireBaseData, errors } = await linkFirebase({
        variables: {
          input: {
            isSmsOptIn: true,
            phoneNumber: result.user.phoneNumber,
          },
        },
      });

      if (errors) {
        throw new Error(errors.map(error => error.message).join('. '));
      }

      // update mparticle identity adn send tracking event
      await mParticle.login({
        email: user.email,
        userId: linkFireBaseData.linkFirebase.userId,
        phoneNumber: user.phoneNumber,
      });

      sendOnboardingEvent({ action: CUSTOM_EVENT_NAME.PHONE_VERIFICATION_COMPLETE });

      // success reset loading and move to next step
      setLoading(false);
      setActiveStep(3);
    } catch(error: any) {
      setLoading(false);
      console.error(error);
      sendOnboardingEvent({
        action: CUSTOM_EVENT_NAME.PHONE_VERIFICATION_ERROR,
        label: `Error: ${error.code}`,
      });
      if (error.code === "auth/account-exists-with-different-credential") {
        setFormPhoneError('Account with phone number already exists');
        setActiveStep(1);
        user?.delete();
      } else if (error.code == 'auth/invalid-verification-code') {
        setOtpError('Incorrect code');
      } else {
        user?.delete();
      }
    }
  };

  return (
    <Styled.Step2 $activeStep={activeStep}>
      <Styled.Logo src={Logo} />

      <Styled.Title>One more step...</Styled.Title>

      <Styled.Description>
        Please enter the 6-digit verification code to confirm that you received
        the text message.
      </Styled.Description>

      <Styled.OtpForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing="10px">
          <Grid item xs={12}>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <Styled.TextFieldCentered
                  fullWidth
                  error={!!errors.otp || !!otpError}
                  helperText={
                    otpError
                      ? otpError
                      : null
                  }
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Styled.Button
              fullWidth
              variant="contained"
              disabled={loading}
              type="submit"
            >
              Next
            </Styled.Button>
          </Grid>
        </Grid>
      </Styled.OtpForm>

      <Styled.CTA>
        Didn&apos;t get the code?{" "}
        <a href="#" onClick={handleSendAgain}>
          Send again
        </a>
        .
      </Styled.CTA>

      <Styled.Subtle>
        Having issues with the process? Please <a href="#">contact support</a>.
      </Styled.Subtle>
    </Styled.Step2>
  );
};

export default Step2;
