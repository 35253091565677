export const PAGE_TITLE = {
  STEP_1: 'Klover | Sign up form',
  STEP_2: 'Klover | Phone verification',
  STEP_3: 'Klover | Success',
};

export const CUSTOM_EVENT_NAME = {
  FORM_VERIFICATION_FIRST: 'Form_Verification_First',
  FORM_VERIFICATION_ERROR: 'Form_Verification_Error',
  PHONE_VERIFICATION_COMPLETE: 'Phone_Verification_Complete',
  PHONE_VERIFICATION_ERROR: 'Phone_Verification_Error',
  DOWNLOAD_KLOVER: 'Download_Klover',
};

export const EVENT_CATEGORY = {
  ONBOARDING: 'onboarding',
};
